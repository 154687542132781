

body {
    background-color: var(--primary-bg-color) ;
}

body a {
    color: var(--primary-text-color);
}

#root {
    position: absolute;
    top: 0;
    min-height: 100vh;
    width: 100%;
}

#side_nav{
    background-color: black;
    min-width: 200px;
    max-width: 200px;
}

.content{
    min-height: 100vh;
    width: 100%;
}

hr.h-color{ 
    background-color: white ;
}

h4 {
    margin-top: 20px;
}

.center-margin {
    margin: auto !important;
}

.limit-width {
    max-width: 1200px;
}

.table {
    border: 2px solid var(--secondary-bg-color)
}

.table-head {
    background-color: var(--secondary-bg-color);
}

.table-cell {
    padding: 5px 25px 5px 25px;
}

.table-row-even {
    background-color: var(--secondary-bg-color);
}

.table-row-odd {
    background-color: var(--primary-bg-color);
}

.warning {
    border: 2px solid var(--warning-text-color);
    color: var(--warning-text-color);
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
}

.sidebar li.active{
    background-color: white;
    border-radius: 8px;
    
}

.sidebar li.active a{
  color: black;

}
.sidebarli a{
    color: white;
}

.sidebarMenuActive {
    background-color: var(--quaternary-bg-color);
}

.sidebarMenuPending {
    background-color: var(--primary-bg-color);
}

/*.sidebar-smartphone-header {
    width: 100%;
    height: 10mm;
    display: none;
    background-color: brown;
    position: fixed;
}*/

.sidebar {
    margin: 0;
    padding: 0;
    width: 50mm;
    background-color: var(--tertiary-bg-color);
    position: fixed;
    top: 0px;
    height: 100%;
    overflow: auto;
    display: block;
    z-index: 2;
}

.sidebar a {
    display: block;
    color: var(--primary-text-color);
    padding: 16px;
    text-decoration: none;
}

.sidebar a:hover:not(.active) {
    background-color: var(--quaternary-bg-color);
}

.sidebar hr {
    height: 4px;
    background-color: var(--primary-bg-color);
    border: none;
}

.sidebar-submenu {
    background-color: var(--secondary-bg-color);
}

@media screen and (min-width: 701px) {
    .sidebar-bottom {
        position: fixed;
        bottom: 0%;
        height: auto;
        width: 50mm;
        padding-bottom: 20px;
        border-top: 4px solid var(--primary-bg-color);
    }
}

@media screen and (max-width: 700px) {
    div.content-container {
        margin-left: 0;
    }
    /*.sidebar-smartphone-header {
        display: block;
    }*/
    .sidebar {
        width: 100%;
        height: auto;
        position: relative;
    }
    .sidebar a {
        text-align: center;
    }
}

.footer {
    background-color: var(--tertiary-bg-color);
    position: absolute;
    bottom: 0;
    width: 100%;height: 15mm;
    /* z-index: -1; */
    text-align: center;
    
}

.footer a {
    padding-left: 15px;
    padding-right: 15px;
}

.content-container {
    margin-left: 50mm;
}

.evjuspaceinput {
    font-size: 16px;
    background-color: var(--quintary-bg-color);
    color: var(--primary-text-color);
    border: 2px solid var(--tertiary-bg-color);
    border-radius: 5px;
}

.evjuspaceinput-container {
    display:inline;
    width: 100%;
}

.evjuspaceinput-error {
    border: 2px solid var(--warning-text-color);
}

.react-datepicker-wrapper {
    width: 100%;
}
.react-datepicker-popper {
    z-index: 3!important;
}

.select-search-container {
    width: 100%!important;
    height: 45px!important;
}

.select-search-input {
    background-color: var(--quintary-bg-color)!important;
    color: var(--primary-text-color)!important;
    border: 2px solid var(--tertiary-bg-color)!important;
    border-radius: 5px!important;
    padding-left: 5px!important;
}

.select-search-select, .select-search-option {
    z-index: 3!important;
    background-color: var(--quintary-bg-color)!important;
    color: var(--primary-text-color)!important;
}

.select-search-select {
    border: 2px solid var(--tertiary-bg-color)!important;
    top: 45px!important;
}

.evjuspaceinput-label {
    display: inline-block;
    background-color: var(--primary-bg-color);
    padding-left: 2mm;
    padding-right: 2mm;
    border-radius: 2mm;
    position: relative;
    transform: translate(10px, 50%);
    z-index: 2;
}

.evjuspaceinput-input {
    height: 45px;
    width: 99%;
}

.evjuspaceinput-input-onsave {
    margin-left: -50px;
    padding: 15px;
}

.evjuspaceinput-input-onsave:hover {
    cursor: pointer;
    color: var(--tertiary-bg-color);
}

.evjuspaceinput:focus {
    border: 2px solid var(--tertiary-bg-color);
    box-shadow: 0 1px 1px var(--quintary-bg-color) inset, 0 0 8px var(--quintary-bg-color);
    outline: 0 none;
}

.evjuspaceinput-button {
    height: 45px;
    width: 30%;
}

.evjuspaceinput-button:hover {
    background-color: var(--tertiary-bg-color);
    border: 2px solid var(--primary-text-color);
}

.evjuspaceinput-checkbox {
    width: 45px;
    height: 45px;
    margin-top: 8px;
    float: left;
}

.evjuspaceinput-checkboxlabel {
    padding-top: 20px;
    padding-bottom: 16px;
}

.evjuspaceeditor {
    background-color: var(--quintary-bg-color);
    padding: 5px;
    border-radius: 3px;
}

.evjuspaceeditor > hr {
    border-color: var(--primary-bg-color);
}

.evjuspaceeditor-button {
    margin: 2px;
    padding: 7px 3px 7px 3px;
    border: none;
}

.evjuspaceeditor-label {
    display: block;
    margin-top: 15px;
}

.evjuspaceuserselector-searchResults {
    background-color: var(--quintary-bg-color);
    border-radius: 5px;
    position: fixed;
    z-index: 10;
}

.evjuspaceuserselector-searchResult {
    padding-left: 15px;
    padding-top: 5px;
    padding-right: 15px;
    padding-bottom: 5px;
}

.evjuspaceuserselector-searchResult:hover {
    background-color: var(--secondary-bg-color);
}

.evjuspaceuserselector-scroll {
    margin-top: 25px;
    max-height: 500px;
    overflow-y: scroll;
}

.card-heading {
    margin-top: 10mm;
    margin-bottom: 10mm;
    color: var(--primary-text-color);
    text-align: center;
}

.card-container{
    background-color: var(--tertiary-bg-color);
    margin: auto;
    margin-bottom: 20mm;
    width: 97%;
    min-width: 400px;
    border-radius: 5mm;
    padding-top: 0;
    padding-bottom: 5mm;
    padding-left: 1mm;
    padding-right: 1mm;
}

.card-container-closed{
    background-color: var(--tertiary-bg-color);
    margin: auto;
    margin-bottom: 5mm;
    width: 97%;
    border-top-left-radius : 5mm;
    border-top-right-radius: 5mm;
    padding-top: 0;
    padding-bottom: 0mm;
    padding-left: 1mm;
    padding-right: 1mm;
}

.card-header {
    color: white;
    height: 12mm;
    padding: 10px;

    display: flex;
    justify-content: space-between;
}

.card-header-closed {
    color: white;
    height: 12mm;
    padding: 10px;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
}

.card-icon {
    text-decoration: none;
    padding: 10px;
}

.card-content {
    background-color: var(--primary-bg-color);
    padding: 5mm;
    color: var(--primary-text-color);
}

.card-form-element {
    background-color: var(--primary-bg-color);
    color: var(--primary-text-color);
    border-color: var(--secondary-bg-color);
    border-radius: 2mm;
    margin: 1mm;
    padding: 3mm;
}

.widget-area {
    display: grid;
    column-gap: 5mm;
    row-gap: 5mm;
}

.widget-area-1 {
    grid-template-columns: 1fr;
}

.widget-area-2 {
    grid-template-columns: 1fr 1fr;
}

.widget-area-3 {
    grid-template-columns: 1fr 1fr 1fr;
}

@media (max-width: 700px) {
    .widget-area-2 {
        grid-template-columns: 1fr;
    }
    .widget-area-3 {
        grid-template-columns: 1fr;
    }
}

@media (min-width: 701px) and (max-width: 1500px) {
    .widget-area-3 {
        grid-template-columns: 1fr 1fr;
    }
}

.widget-heading {
    margin-top: 10mm;
    margin-bottom: 10mm;
    color: var(--primary-text-color);
    text-align: center;
}

.widget-container{
    display: inline-grid;
    background-color: var(--tertiary-bg-color);
    margin: auto;
    margin-bottom: 5mm;
    width: 100%;
    border-radius: 5mm;
    padding-top: 0;
    padding-bottom: 5mm;
    padding-left: 1mm;
    padding-right: 1mm;
}

.widget-header {
    color: white;
    height: 12mm;
    padding: 10px;

    display: flex;
    justify-content: space-between;
}

.widget-icon {
    text-decoration: none;
    padding: 10px;
}

.widget-content {
    overflow: auto;
    background-color: var(--primary-bg-color);
    padding: 5mm;
    color: var(--primary-text-color);
    scrollbar-color: var(--tertiary-bg-color) var(--primary-bg-color);
    scrollbar-width: thin;
}

.widget-content::-webkit-scrollbar {
    width: 3px;
}

.widget-content::-webkit-scrollbar-track {
    background-color: var(--primary-bg-color);
}

.widget-content::-webkit-scrollbar-thumb {
    background-color: var(--tertiary-bg-color);
}

.widget-standard-height {
    height: 200px;
}

.list-icon {
    text-decoration: none;
    padding: 3px;
}

.formerror {
    color: var(--warning-text-color);
}

.agendaitem-container {
    margin-bottom: 4mm;
}

.agendaitem {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--secondary-bg-color);
}

.agendaitem-number {
    margin-right: 5mm;
}

.agendaitem-text {
}

.agendaitem-tools {
}

.subagendaitem {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--secondary-bg-color);
}

.subagendaitem-number {
    margin-right: 10mm;
}

.protocolitemlink{
    color: var(--quaternary-bg-color);
    margin-right: 15px;
}

.protocolitem{
    padding: 5px;
    border-radius: 3px;;
}

.protocolitem:hover {
    background-color: var(--secondary-bg-color);
}

/* .protocolitemedit{
    text-decoration: underline;
    float: right;
} */

.protocolitemresulteditor {
    background-color: var(--quintary-bg-color);
    border-radius: 3px;
    margin-top: 5px;
    padding: 5px;
}

.protocolitemresulteditoroption {
    width: 60%;
}

.subAgendaProtocol {
    /* border-top: 2px solid var(--secondary-bg-color); */
    border-left: 2px solid var(--secondary-bg-color);
    margin-bottom: 10px;
    padding-left: 10px;
}

.eventcontrolcategory {
    margin-bottom: 25px;
}

.eventcontrolcategory-label {
    display: block;
}

.overlay_background {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 9;
}

.overlay_container {
    background-color: var(--primary-bg-color);
    border-radius: 20px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 10;
    padding: 30px;
    min-width: 250px;
    width: fit-content;
    max-width: 80%;
    min-height: 250px;
    height: fit-content;
    max-height: 80%;
    overflow: auto;
}

.overlay_controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.overlay_close {
    border: none;
    background-color: transparent;
    font-size: 50px;
    cursor: pointer;
}

.overlay_close:after {
    display: inline-block;
    content: "\00d7";
}

.next_button {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.toggle-button {
    font-size: xx-large;
}

.save_button {
    padding: 10px;
    font-size: x-large;
}

.certification-box {
    margin-bottom: 5px;
    padding: 5px 10px;
    border: 2px solid var(--secondary-bg-color);
    border-radius: 5px;
}

.certification-data {
    display: flow-root;
}

.certification-detail {
    float: left;
    margin-right: 60px;
}

.certification-control {
    float: right;
    padding: 15px;
}

.profile-training-link {
    display: block;
    margin-top: 20px;
}

.profile-add-training-preset {
    padding: 15px;
    margin: 5px;
    border: 2px solid var(--secondary-bg-color);
    border-radius: 5px;
}

.profile-add-training-preset:hover {
    background-color: var(--secondary-bg-color);
}

#bugreportlink {
    color: var(--warning-text-color);
}

.icon_grid div {
    clear: both;
}

.icon_grid a {
    display: block;
    position: relative;
    float: left;
    border: 2px solid var(--secondary-bg-color);
    border-radius: 15px;
    margin: 15px;
    padding: 15px;
    width: 125px;
    height: 125px;
}

.icon_grid i {
    font-size: 90px;
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translate(-50%, 0);
}

.icon_grid label {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    font-size: 16px;
}

.inactive {
    color: var(--secondary-bg-color);
}

.inactive:hover {
    color: var(--secondary-bg-color);
}

.wsreg_workshop_row {
    border-bottom: 2px solid var(--secondary-bg-color);
    padding: 15px;
}

.wsreg_workshop_row:hover {
    background-color: var(--secondary-bg-color);
    cursor: pointer;
}

.wsreg_workshop_numbers {
    float: right;
}

.wsreg_workshops {
    border: 2px, solid var(--secondary-bg-color);
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 15px;
}

.wsreg_workshop_registered {
    background-color: var(--secondary-bg-color);
    border-left: 10px solid #013d07;
}

.wsreg_workshop_full {
    background-color: var(--primary-bg-color);
    color: var(--quaternary-bg-color);
}

.wsreg_workshop_full:hover {
    background-color: var(--primary-bg-color);
    color: var(--quaternary-bg-color);
    cursor: default;
}

.wsreg_warn {
    border: 2px solid var(--warning-text-color);
    border-radius: 5px;
    padding: 15px;
    color: var(--warning-text-color);
}

.wsreg_info {
    border: 2px solid var(--secondary-bg-color);
    border-radius: 5px;
    padding: 15px;
}