:root {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  --primary-bg-color: #272f3f;
  --secondary-bg-color: #1d232f;
  --tertiary-bg-color: #191e28;
  --quaternary-bg-color: #0a0c10;
  --quintary-bg-color: #57698c;

  --primary-text-color: #e0e4eb;
  --warning-text-color: orange;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}


body {
  margin: 0;
  min-width: 320px;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

/* button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: #646cff;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
} */

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.error-page {
  color: var(--primary-text-color);
  text-align: center;
  margin: auto;
}

.login-container {
  width: 500px;
  padding: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid var(--secondary-bg-color);
  border-radius: 10px;
}

.login-button {
  margin-top: 20px;
  width: 100% !important;
}

#login-links {
  display: flex;
}

.login-link {
  flex: 1;
  padding-top: 15px;
  padding-bottom: 15px;
}

.login-error {
  color: var(--warning-text-color);
}

.forgot-password-link {
  /* float: right; */
  /* display: inline; */
  /* flex: 1; */
}

.register-link {
  text-align: right;
}

.staranimation_sky {
  background-color: var(--quaternary-bg-color);
  position: absolute;
  transform: translate(0, -50%);
  height: 100vh;
  width: 100vw
}

.staranimation_constellation {
  position: absolute;
  width: 300px;
  height: 300px;
  left: 100px;
  animation: move_constallation1 100s ease-in-out infinite;
}

.staranimation_star {
  position: absolute;
  background-color: white;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.staranimation1 {
  animation: twinkle1 1s ease-in-out infinite;
}

.staranimation2 {
  animation: twinkle1 3s ease-in-out infinite;
}

.staranimation3 {
  animation: twinkle1 6s ease-in-out infinite;
}

.staranimation4 {
  animation: twinkle2 3s ease-in-out infinite;
}

.staranimation5 {
  animation: twinkle2 5s ease-in-out infinite;
}

.staranimation6 {
  animation: twinkle2 7s ease-in-out infinite;
}

@keyframes twinkle1 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes twinkle2 {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  90% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes move_constallation1 {
  0% {
    left: 100px;
    top: 20px;
  }
  20% {
    left: 150px;
    top: 40px;
  }
  40% {
    left: 170px;
    top: 80px;
  }
  60% {
    left: 120px;
    top: 100px;
  }
  80% {
    left: 60px;
    top: 40px;
  }
  100% {
    left: 100px;
    top: 20px;
  }
}